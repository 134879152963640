@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  z-index: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  scrollbar-width: 0;
  
}
::-webkit-scrollbar{
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.flexBox {
  display: flex;
  width: 300px;
  height: 100px;
}
.gridItem {
  outline: 1px solid black;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
}
